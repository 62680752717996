export default {
  general: {
    username: 'Имя пользователя',
    password: 'Пароль',
    newpass: 'Новый пароль',
    currentpass: 'Текущий пароль',
    c_password: 'Подтвердить пароль',
    c_newpass: 'Подтвердить новый пароль',
    email: 'Email адрес',
    language: 'Язык',
    subject: 'Тема',
    message: 'Сообщение',
    filtername: 'Название фильтра',
    placeholders: {
      username: 'Введите имя пользователя',
      password: 'Введите пароль',
      newpass: 'Введите новый пароль',
      currentpass: 'Введите текущий пароль',
      c_password: 'Подтвердить пароль',
      c_newpass: 'Подтвердить новый пароль',
      email: 'Введите email',
      subject: 'Введите тему',
      message: 'Введите ваше сообщение',
      filtername: 'Введите название'
    },
    errors: {
      language: {
        load: 'Ошибка при смене языка.'
      }
    },
    here: 'здесь',
    ok: 'ОК',
    cancel: 'Отменить',
    on: 'вкл',
    off: 'выкл',
    min: 'Минимальное значение',
    max: 'Максимально значение',
    years: '{n} год | {n} лет',
    days: '{n} день | {n} дней',
    type_for_more: 'Нажмите, чтобы увидеть больше...'
  },
  notifications: {
    disabled: '{notifications} заблокированы. Если вы не блокировали их, попробуйте другой браузер.',
    notifications: 'Уведомления',
    title: 'Настройки уведомлений',
    form: {
      email: 'E-mail уведомления',
      email_frequency: 'Частота уведомлений почтой',
      browser: 'Уведомления в браузере',
      browser_frequency: 'Частота уведомлений в браузере',
      frequency: 'мгновенно | каждые {n} часов | каждые {n} часов',
      help: {
        email: 'Вы будете получать уведомления об игроках на e-mail.',
        browser: 'Вы будете получать уведомления об игроках прямо в вашем браузере.',
        frequency: 'Рекомендуемое значение \'мгновенно\', чтобы не упустить игроков.',
        blocked: 'Вы заблокировали уведомления в браузере или ваш браузер не поддерживает их.'
      }
    }
  },
  header: {
    menu: {
      home: 'Главная',
      account: 'Аккаунт',
      signin: 'Войти',
      register: 'Зарегистрироваться',
      inbox: 'Входящие',
      blog: 'Блог',
      contact: 'Контакты',
      logout: 'Выйти'
    }
  },
  footer: {
    menu: {
      privacy: 'Приватность (английский)',
      terms: 'Условия использования (английский)'
    }
  },
  login: {
    title: 'Войти',
    signin: 'Войти',
    register: 'Зарегистрироваться',
    forgot: 'Забыли пароль?',
    form: {
      submit: 'Войти',
      remember: 'Запомнить'
    },
    info: {
      what: 'Зачем нужен Mercattrick?',
      purpose: 'Цели создания Mercattrick:',
      purpose1: 'помочь <strong>менеджерам</strong> Hattrick в продвижении и рекламе игроков, которых они <strong>продают</strong>, чтобы получить максимум от продажи;',
      purpose2: 'помочь <strong>менеджерам</strong> Hattrick, которые <strong>хотят купить</strong> игроков определенного типа, отслеживать появление таких игроков на трансфере и получать об этом уведомление',
      how: 'Как это работает?',
      buyers: 'Покупатели',
      step1: 'авторизуйтесь и настройте фильтр навыков и характеристик игроков, о продаже которых вы хотите получать уведомления.',
      sellers: 'Продавцы',
      step2: 'выставьте игрока на трансфер в HT, авторизуйтесь здесь и нажмитке кнопку "рекламировать" для этого игрока.',
      promoting: 'Рекламирование',
      step3: 'игрока означает, что все "покупатели", заинтересованные в подобном игроке, получат уведомление о том, что игрок выставлен на трансфер.'
    }
  },
  register: {
    title: 'Регистрация',
    form: {
      registered: 'Ваш аккаунт создан. Нажмите {here} для продолжения.',
      submit: 'Зарегистрироваться',
      agreement: 'Нажимая @:register.form.submit вы соглашаетесь с {terms} и {privacy}, а также с получением писем, связанных с вашим аккаунтом.',
      tos: 'условия использования',
      privacy: 'приватность',
      help: {
        username: 'Это не ваше имя пользователя в HT.',
        password: 'Введите минимум 6 символов. Пароль чувствителен к регистру.',
        email: 'Мы будем использовать этот адрес для уведомлений о новых игроках.'
      }
    }
  },
  recover: {
    title: 'Восстановление пароля',
    form: {
      recovered: 'Письмо со ссылкой для сброса пароля выслано на вашу почту.',
      submit: 'Восстановить',
      help: {
        email: 'Это должен быть адрес, указанный в параметрах вашего аккаунта.'
      }
    }
  },
  reset: {
    title: 'Сброс пароля',
    form: {
      submit: 'Сбросить',
      help: {
        email: 'Это должен быть адрес, указанный в параметрах вашего аккаунта.',
        password: 'Введите минимум 6 символов. Пароль чувствителен к регистру.'
      }
    }
  },
  contact: {
    title: 'Контакты',
    form: {
      success: 'Сообщение успешно отправлено.',
      submit: 'Отправить',
      help: {
        email: 'Мы используем этот адрес только для отправки ответа.'
      }
    }
  },
  sync: {
    users: {
      syncing: 'Синхронизация пользователя...',
      fail: 'Ошибка синхронизации пользователя.',
      retry: 'Попробовать еще раз',
      authorize: 'Вам необходимо авторизовать данный CHPP сервис. Нажмите {here}, чтобы открыть сайт Hattrick.',
      authorizing: 'Подготовка к авторизации. Сейчас вы будете перенаправлены на <strong>hattrick.org</strong>...',
      finalize: 'Завершение авторизации...'
    },
    system: {
      syncing: 'Синхронизация системы...',
      fail: 'Ошибка синхронизации системы. {retry} или {skip}!',
      retry: 'Попробовать еще раз',
      skip: 'Пропустить'
    }
  },
  account: {
    account: {
      title: 'Настройки аккаунта',
      form: {
        success: 'Аккаунт успешно обновлен.',
        submit: 'Сохранить',
        help: {
          username: 'Это не ваше имя пользователя в HT.',
          email: 'Мы будем использовать этот адрес для уведомлений о новых игроках.'
        }
      }
    },
    login: {
      title: 'Настройки входа',
      form: {
        success: 'Пароль успешно обновлен.',
        submit: 'Сохранить',
        help: {
          passwords: 'Введите ваш текущий пароль, новый пароль и подтверждение нового пароля.'
        }
      }
    },
    privacy: {
      title: 'Настройки приватности',
      form: {
        submit: 'Удалить аккаунт',
        delete: 'Удалить',
        help: {
          warning: 'Это действие необратимо. Все ваши данные будут удалены безвозвратно.',
          confirmation: 'Пожалуйста, подтвердите, что вы хотит удалить аккаунт навсегда.'
        }
      }
    }
  },
  filter: {
    title: 'Нажмите для добавления/удаления фильтра',
    form: {
      submit: 'Сохранить',
      set: 'Установить',
      remove: 'Удалить',
      help: {
        name: 'Дайте вашему фильтру говорящее название для удобства использования.'
      }
    },
    tooltip: {
      save: 'Вы должны установить хотя бы одно условие для фильтра!'
    },
    item: {
      tooltip: 'Нажмите \'@:filter.item.show_players\', чтобы показать список.',
      show_players: 'Показать игроков',
      hide_players: 'Скрыть игроков',
      players: 'игрок найден | игроков найдено',
      form: {
        processing: 'обработка',
        fetch: 'Выгружаем игроков HT',
        edit: 'Редактировать',
        delete: 'Удалить',
        help: {
          confirm: 'Вы уверены, что хотите удалить этот фильтр?'
        }
      }
    }
  },
  home: {
    myplayers: {
      title: 'Мои трансферы',
      loading: 'Загрузка игроков...',
      none: 'У вас нет игроков на трансфере в данный момент.',
      promoted: 'прорекламирован',
      late: 'слишком поздно, чтобы рекламировать',
      form: {
        reload: 'Обновить',
        promote: 'Рекламировать'
      },
      tooltip: 'Прорекламируйте ваших игроков, чтобы другие пользователи увидели их.'
    },
    bookmarks: {
      title: 'Закладки',
      loading: 'Загрузка игроков...',
      none: 'У вас нет игроков в закладках в данный момент.',
      form: {
        reload: 'Обновить',
        unbookmark: 'Удалить закладку',
        help: {
          confirm: 'Вы уверены, что хотите удалить эту закладку?'
        }
      },
      tooltip: 'Вы получите уведомления об этих игроках, когда они будут близки к окончанию торгов.'
    },
    filters: {
      title: 'Мои фильтры',
      loading: 'Загрузка фильтров...',
      none: 'У вас нет ни одного фильтра. Создайте хотя бы один.',
      form: {
        reload: 'Обновить',
        add: 'Добавить фильтр',
        bookmark: 'Добавить в закладки',
        hide: 'Скрыть',
        help: {
          confirm: 'Вы уверены, что хотите скрыть этого игрока?'
        }
      },
      tooltip: 'Добавьте интересующих вас игроков из этих списков в закладки.'
    }
  },

  player: {
    attributes: {
      name: 'Имя',
      age: 'Возраст',
      form: 'Форма',
      experience: 'Опыт',
      leadership: 'Лидерство',
      stamina_skill: 'Выносливость',
      keeper_skill: 'Вратарь',
      playmaker_skill: 'Полузащита',
      scorer_skill: 'Нападение',
      passing_skill: 'Пас',
      winger_skill: 'Фланг',
      defender_skill: 'Защита',
      set_pieces_skill: 'Стандарты',
      agreeability: 'Характер',
      aggressiveness: 'Темперамент',
      honesty: 'Честность',
      htms: 'HTMS',
      htms28: 'HTMS28',
      tsi: 'TSI',
      salary: 'Зарплата',
      price: 'Цена',
      asking_price: 'Запрашиваемая цена',
      highest_bid: 'Текущая ставка',
      native_country: 'Страна происхождения',
      native_country_id: 'Страна происхождения',
      NT_player: 'Игрок национальной сборной',
      specialties: 'Специализации',
      specialty: 'Специализация'
    },
    redcard: 'Красные карточки',
    yellowcard: 'Желтые карточки',
    bruised: 'Слегка травмирован, но может играть ',
    injured: 'Травмирован',
    injury: '{n} неделя | {n} недель',
    fullage: '{years} лет и {days} дней',
    deadline: 'Окончание торгов',
    htms: 'HTMS/HTMS28',
    more: 'Показать больше',
    less: 'Свернуть',
    levels: {
      skill: [
        'отсутствует',
        'катастрофично',
        'ужасно',
        'плохо',
        'слабо',
        'недостаточно',
        'сносно',
        'хорошо',
        'отлично',
        'превосходно',
        'восхитительно',
        'блестяще',
        'потрясающе',
        'мирового класса',
        'сверхъестественно',
        'колоссально',
        'запредельно',
        'сказочно',
        'волшебно',
        'легендарно',
        'божественно',
        'божественно (+1)',
        'божественно (+2)',
        'божественно (+3)',
        'божественно (+4)',
        'божественно (+5)'
      ],
      agreeability: [
        'мерзкий тип',
        'неприятный человек',
        'приятный парень',
        'обаятельный парень',
        'популярный парень',
        'всеобщий любимец'
      ],
      aggressiveness: [
        'безмятежный',
        'спокойный',
        'уравновешенный',
        'темпераментный',
        'вспыльчивый',
        'агрессивный'
      ],
      honesty: [
        'подлый',
        'нечестный',
        'честный',
        'справедливый',
        'праведный',
        'святой'
      ],
      specialty: [
        'нет',
        'техничный',
        'быстрый',
        'мощный',
        'непредсказуемый',
        'игра головой',
        'крепкое здоровье',
        '',
        'командный игрок'
      ],
      national_team: [
        'никогда',
        'в данный момент нет',
        'в данный момент да'
      ],
      any: 'любой/нет'
    },
    tooltips: {
      highest_bid: 'Текущая ставка может быть неактуальной!'
    }
  },
  countries: {
    1: 'Швеция',
    2: 'Англий',
    3: 'Германия',
    4: 'Италия',
    5: 'Франция',
    6: 'Мексика',
    7: 'Аргентина',
    8: 'США',
    9: 'Норвегия',
    10: 'Дания',
    11: 'Финляндия',
    12: 'Нидерланды',
    13: 'Океания',
    14: 'Канада',
    15: 'Шотландия',
    16: 'Ирландия',
    17: 'Чили',
    18: 'Колумбия',
    19: 'Уругвай',
    20: 'Венесуэла',
    21: 'Перу',
    22: 'Бразилия',
    23: 'Португалия',
    24: 'ЮАР',
    25: 'Япония',
    26: 'Польша',
    27: 'Индия',
    28: 'Китай',
    29: 'Южная Корея',
    30: 'Тайланд',
    31: 'Турция',
    32: 'Египет',
    33: 'Австрия',
    34: 'Россия',
    35: 'Испания',
    36: 'Румыния',
    37: 'Исландия',
    38: 'Бельгия',
    39: 'Малайзия',
    40: 'Швейцария',
    41: 'Сингапур',
    42: 'Хорватия',
    43: 'Сербия',
    44: 'Венгрия',
    45: 'Греция',
    46: 'Чехия',
    47: 'Эстония',
    48: 'Латвия',
    49: 'Индонезия',
    50: 'Филиппины',
    51: 'Израиль',
    52: 'Китайский Тайбэй',
    53: 'Гонг Конг',
    55: 'Болгария',
    56: 'Уэльс',
    57: 'Словения',
    61: 'Литва',
    62: 'Украина',
    63: 'Босния и Герцеговина',
    64: 'Пакистан',
    65: 'Вьетнам',
    66: 'Словакия',
    67: 'Парагвай',
    68: 'Эквадор',
    69: 'Боливия',
    70: 'Нигерия',
    71: 'Фарерские острова',
    72: 'Марокко',
    75: 'Саудовская Аравия',
    76: 'Тунис',
    77: 'Коста Рика',
    78: 'Объединенный Арабские Эмираты',
    79: 'Люксембург',
    80: 'Иран',
    82: 'Кипр',
    83: 'Доминиканская Республика',
    86: 'Сенегал',
    87: 'Беларусь',
    88: 'Северная Ирландия',
    89: 'Ямайка',
    90: 'Кения',
    91: 'Панама',
    92: 'Македония',
    93: 'Куба',
    94: 'Албания',
    95: 'Гондурас',
    96: 'Эль Сальвадор',
    97: 'Мальта',
    98: 'Киргизстан',
    99: 'Молдова',
    100: 'Грузия',
    101: 'Андорра',
    102: 'Гватемала',
    103: 'Иордания',
    104: 'Армения',
    105: 'Тринидад и Тобаго',
    121: 'Никарагуа',
    122: 'Казахстан',
    123: 'Суринам',
    125: 'Лихтенштейн',
    126: 'Алжир',
    127: 'Монголия',
    128: 'Ливан',
    129: 'Бахрейн',
    130: 'Барбадос',
    131: 'Кабо-Верде',
    132: 'Кот-д\'Ивуар',
    133: 'Азербайджан',
    134: 'Кувейт',
    135: 'Ирак',
    136: 'Черногория',
    137: 'Ангола',
    138: 'Бангладеш',
    139: 'Йемен',
    140: 'Оман',
    142: 'Мозамбик',
    143: 'Бруней',
    144: 'Гана',
    145: 'Камбоджия',
    147: 'Бенин',
    148: 'Сирия',
    149: 'Катар',
    150: 'Танзания',
    153: 'Уганда',
    154: 'Мальдивы',
    163: 'Узбекистан',
    165: 'Камерун',
    166: 'Палестина',
    175: 'Шри-Ланка',
    177: 'Сан-Томе и Принсипи',
    178: 'Курасао',
    179: 'Гуам',
    180: 'Коморские острова',
    181: 'ДР Конго',
    182: 'Эфиопия',
    183: 'Мадагаскар',
    184: 'Сент-Винсент и Гренадины',
    185: 'Ботсвана',
    186: 'Белиз',
    187: 'Замбия',
    188: 'Гаити',
    189: 'Мьянма',
    190: 'Пуэрто-Рико',
    191: 'Сан-Марино',
    192: 'Непал',
    193: 'Буркина-Фасо',
    194: 'Гренада',
    196: 'Таити',
    197: 'Гайана'
  },

  validation: {
    attributes: {},
    messages: {
      alpha_num: 'Это поле может содержать только буквы и цифры.',
      alpha_dash: 'Это поле может содержать только буквы, цифры, знак подчеркивания или тире.',
      confirmed: 'Подтверждение адреса не совпадает с введенным адресом.',
      email: 'Это поле должно содержать e-mail адрес.',
      max: 'Это поле содержит слишком много символов.',
      min: 'Это поле содержит слишком мало символов.',
      required: 'Это обязательное поле.'
    }
  },

  api: {
    validation: {
      string: {
        subject: 'Тема обязательна.',
        body: 'Содержание обязательно.',
        username: 'Имя пользователя обязательно.',
        password: 'Пароль обязателен.',
        locale: 'Язык обязателен.',
        name: 'Имя обязательно.',
        token: 'Токен обязателен.'
      },
      integer: {
        native_country_id: 'ID страны должен быть числом.',
        htms_min: 'Минимальный HTMS должен быть числом.',
        htms_max: 'Максимальный HTMS должен быть числом.',
        htms28_min: 'Минимальный HTMS28 должен быть числом.',
        htms28_max: 'Максимальный HTMS28 должен быть числом.',
        tsi_min: 'Минимальный TSI должен быть числом.',
        tsi_max: 'Максимальный TSI должен быть числом.'
      },
      numeric: {
        age_min: 'Минимальный возраст должен быть числом.',
        age_max: 'Максимальный возраст должен быть числом.',
        salary_min: 'Минимальная зарплата должна быть числом.',
        salary_max: 'Максимальная зарплата должна быть числом.',
        price_min: 'Минимальная запрашиваемая цена должна быть числом.',
        price_max: 'Максимальная запрашиваемая цена должна быть числом.'
      },
      required: {
        subject: 'Тема обязательна.',
        body: 'Содержание обязательно.',
        username: 'Имя пользователя обязательно.',
        password: 'Пароль обязателен.',
        current_password: 'Текущий пароль обязателен.',
        new_password: 'Новый пароль обязателен.',
        email: 'Адрес обязателен.',
        token: 'Токен обязателен.',
        endpoint: 'Параметр \'endpoint\' обязателен.',
        url: 'Параметр \'URL\' обязателен.',
        oauth_token: 'Параметр \'oauth_token\' обязателен.',
        oauth_verifier: 'Параметр \'oauth_verifier\' обязателен.'
      },
      max: {
        age_min: 'Минимальный возраст должен быть меньше 100.',
        age_max: 'Максимальный возраст должен быть меньше 100.',
        htms_min: 'Минимальный HTMS должен быть меньше 12 000.',
        htms_max: 'Максимальный HTMS должен быть меньше 12 000.',
        htms28_min: 'Минимальный HTMS28 должен быть меньше 12 000.',
        htms28_max: 'Максимальный HTMS28 должен быть меньше 12 000.',
        tsi_min: 'Минимальный TSI должен быть меньше 16 000 000.',
        tsi_max: 'Максимальный TSI должен быть меньше 16 000 000.',
        subject: 'Тема содержит слишком много символов.',
        username: 'Имя пользователя содержит слишком много символов.',
        email: 'Адрес e-mail содержит слишком много символов.',
        locale: 'Язык содержит слишком много символов.',
        name: 'Имя содержит слишком много символов.',
        token: 'Токен содержит слишком много символов.',
        NT_player: 'Значение игрока национальной сборной должно быть не больше 2 (\'@:player.levels.national_team[2]\').',

        string: {
          subject: '@:api.validation.max.subject',
          username: '@:api.validation.max.username',
          email: '@:api.validation.max.email',
          locale: '@:api.validation.max.locale',
          name: '@:api.validation.max.name',
          token: '@:api.validation.max.token',
          NT_player: '@:api.validation.max.NT_player'
        },

        numeric: {
          age_min: '@:api.validation.max.age_min',
          age_max: '@:api.validation.max.age_max',
          htms_min: '@:api.validation.max.htms_min',
          htms_max: '@:api.validation.max.htms_max',
          htms28_min: '@:api.validation.max.htms28_min',
          htms28_max: '@:api.validation.max.htms28_max',
          tsi_min: '@:api.validation.max.tsi_min',
          tsi_max: '@:api.validation.max.tsi_max',
          NT_player: '@:api.validation.max.NT_player'
        }
      },
      min: {
        password: 'Пароль содержит слишком мало символов.',
        age_min: 'Минимальный возраст должен быть больше 17.',
        age_max: 'Максимальный возраст должен быть больше 17.',
        native_country_id: 'ID страны должен быть больше 0.',
        htms_min: 'Минимальный HTMS должен быть больше 0.',
        htms_max: 'Максимальный HTMS должен быть больше 0.',
        htms28_min: 'Минимальный HTMS28 должен быть больше -12 000.',
        htms28_max: 'Максимальный HTMS28 должен быть больше -12 000.',
        tsi_min: 'Минимальный TSI должен быть больше 0.',
        tsi_max: 'Максимальный TSI должен быть больше 0.',
        salary_min: 'Минимальная зарплата должна быть больше 0.',
        salary_max: 'Максимальная зарплата должна быть больше 0',
        price_min: 'Минимальная запрашиваемая цена должна быть больше 0.',
        price_max: 'Максимальная запрашиваемая цена должна быть больше 0',
        NT_player: 'Значение игрока национальной сборной должно быть не меньше 0 (\'@:player.levels.national_team[0]\').',

        string: {
          password: '@:api.validation.min.password',
          age_min: '@:api.validation.min.age_min',
          age_max: '@:api.validation.min.age_max',
          native_country_id: '@:api.validation.min.native_country_id',
          htms_min: '@:api.validation.min.htms_min',
          htms_max: '@:api.validation.min.htms_max',
          htms28_min: '@:api.validation.min.htms28_min',
          htms28_max: '@:api.validation.min.htms28_max',
          tsi_min: '@:api.validation.min.tsi_min',
          tsi_max: '@:api.validation.min.tsi_max',
          salary_min: '@:api.validation.min.salary_min',
          salary_max: '@:api.validation.min.salary_max',
          price_min: '@:api.validation.min.price_min',
          price_max: '@:api.validation.min.price_max',
          NT_player: '@:api.validation.min.NT_player'
        },

        numeric: {
          age_min: '@:api.validation.min.age_min',
          age_max: '@:api.validation.min.age_max',
          native_country_id: '@:api.validation.min.native_country_id',
          htms_min: '@:api.validation.min.htms_min',
          htms_max: '@:api.validation.min.htms_max',
          htms28_min: '@:api.validation.min.htms28_min',
          htms28_max: '@:api.validation.min.htms28_max',
          tsi_min: '@:api.validation.min.tsi_min',
          tsi_max: '@:api.validation.min.tsi_max',
          salary_min: '@:api.validation.min.salary_min',
          salary_max: '@:api.validation.min.salary_max',
          price_min: '@:api.validation.min.price_min',
          price_max: '@:api.validation.min.price_max',
          NT_player: '@:api.validation.min.NT_player'
        }
      },
      email: {
        email: 'Поле для e-mail адреса должно содержать корректный адрес.'
      },
      url: {
        url: 'Параметр \'URL\' должен содержать корректный URL.'
      },
      in: {
        endpoint: 'Параметр \'endpoint\' должен быть корректным.',
        form_min: 'Минимальное значение формы должно быть между 1 (\'@:player.levels.skill[1]\') и 8 (\'@:player.levels.skill[8]\').',
        form_max: 'Максимальное значение формы должно быть между 1 (\'@:player.levels.skill[1]\') и 8 (\'@:player.levels.skill[8]\').',
        agreeability_min: 'Минимальное значение характера должно быть между 0 (\'@:player.levels.agreeability[0]\') и 5 (\'@:player.levels.agreeability[5]\').',
        agreeability_max: 'Максимальное значение характера должно быть между 0 (\'@:player.levels.agreeability[0]\') и 5 (\'@:player.levels.agreeability[5]\').',
        aggressiveness_min: 'Минимальное значение темперамента должно быть между 0 (\'@:player.levels.aggressiveness[0]\') и 5 (\'@:player.levels.aggressiveness[5]\').',
        aggressiveness_max: 'Максимальное значение темперамента должно быть между 0 (\'@:player.levels.aggressiveness[0]\') и 5 (\'@:player.levels.aggressiveness[5]\').',
        honesty_min: 'Минимальное значение честности должно быть между 0 (\'@:player.levels.honesty[0]\') и 5 (\'@:player.levels.honesty[5]\').',
        honesty_max: 'Максимальное значение честности должно быть между 0 (\'@:player.levels.honesty[0]\') и 5 (\'@:player.levels.honesty[5]\').',
        experience_min: 'Минимальное значение опыта должно быть между 0 (\'@:player.levels.skill[0]\') и 25 (\'@:player.levels.skill[25]\').',
        experience_max: 'Максимальное значение опыта должно быть между 0 (\'@:player.levels.skill[0]\') и 25 (\'@:player.levels.skill[25]\').',
        leadership_min: 'Минимальное значение лидерства должно быть между 1 (\'@:player.levels.skill[1]\') и 8 (\'@:player.levels.skill[8]\').',
        leadership_max: 'Максимальное значение лидерства должно быть междуn 1 (\'@:player.levels.skill[1]\') и 8 (\'@:player.levels.skill[8]\').',
        stamina_skill_min: 'Минимальное значение выносливости должно быть между 0 (\'@:player.levels.skill[0]\') и 9 (\'@:player.levels.skill[9]\').',
        stamina_skill_max: 'Максимальное значение выносливости должно быть между 0 (\'@:player.levels.skill[0]\') и 9 (\'@:player.levels.skill[9]\').',
        keeper_skill_min: 'Минимальный навык вратаря должен быть между 0 (\'@:player.levels.skill[0]\') и 25 (\'@:player.levels.skill[25]\').',
        keeper_skill_max: 'Максимальный навык вратаря должен быть между 0 (\'@:player.levels.skill[0]\') и 25 (\'@:player.levels.skill[25]\').',
        playmaker_skill_min: 'Минимальный навык полузащиты должен быть между 0 (\'@:player.levels.skill[0]\') и 25 (\'@:player.levels.skill[25]\').',
        playmaker_skill_max: 'Максимальный навык полузащиты должен быть между 0 (\'@:player.levels.skill[0]\') и 25 (\'@:player.levels.skill[25]\').',
        scorer_skill_min: 'Минимальный навык нападения должен быть между 0 (\'@:player.levels.skill[0]\') и 25 (\'@:player.levels.skill[25]\').',
        scorer_skill_max: 'Максимальный навык нападения должен быть между 0 (\'@:player.levels.skill[0]\') и 25 (\'@:player.levels.skill[25]\').',
        passing_skill_min: 'Минимальный навык паса должен быть между 0 (\'@:player.levels.skill[0]\') и 25 (\'@:player.levels.skill[25]\').',
        passing_skill_max: 'Максимальный навык паса должен быть между 0 0 (\'@:player.levels.skill[0]\') и 25 (\'@:player.levels.skill[25]\').',
        winger_skill_min: 'Минимальный навык фланга должен быть между 0 (\'@:player.levels.skill[0]\') и 25 (\'@:player.levels.skill[25]\').',
        winger_skill_max: 'Максимальный навык фланга должен быть между 0 (\'@:player.levels.skill[0]\') и 25 (\'@:player.levels.skill[25]\').',
        defender_skill_min: 'Минимальный навык защиты должен быть между 0 (\'@:player.levels.skill[0]\') и 25 (\'@:player.levels.skill[25]\').',
        defender_skill_max: 'Максимальный навык защиты должен быть между 0 (\'@:player.levels.skill[0]\') и 25 (\'@:player.levels.skill[25]\').',
        set_pieces_skill_min: 'Минимальный навык стандартов должен быть между 0 (\'@:player.levels.skill[0]\') и 25 (\'@:player.levels.skill[25]\').',
        set_pieces_skill_max: 'Максимальный навык стандартов должен быть между 0 (\'@:player.levels.skill[0]\') и 25 (\'@:player.levels.skill[25]\').'
      },
      exists: {
        user_id: 'Пользователь больше не существует.'
      },
      boolean: {
        promoted: 'Параметр \'promoted\' должен быть true или false.',
        bookmarked: 'Параметр \'bookmarked\' должен быть true или false.',
        ignored: 'Параметр \'ignored\' должен быть true или false.',
        notifications_email: 'Параметр \'notifications_email\' должен быть true или false.',
        notifications_desktop: 'Параметр \'notifications_desktop\' должен быть true или false.'
      },
      array: {
        specialties: 'Специализации должны содержать массив значений.'
      }
    },
    unknown: {
      general: 'Случилась непредвиденная ошибка. Попробуйте перезагрузить страницу.'
    },
    network: {
      general: 'Проблема подключения.',
      maintenance: 'Идут технические работы. Попробуйте еще раз через несколько минут.'
    },
    denied: {
      access: 'Доступ запрещен.'
    },
    expired: {
      token: 'Токен аутентификации устарел.',
      session: 'Пользователь не аутентифицирован.'
    },
    failed: {
      registration: 'Невозможно завершить регистрацию.',
      user_details: 'Невозможно получить параметры пользователя.',
      player_details: 'Невозможно получить параметры игрока.',
      filter_fetch: 'Невозможно выгрузить новых игроков.',
      password_update: 'Пароль не изменен.',
      chpp_connection: 'Невозможно стартовать CHPP подключение.',
      chpp_authorization: 'Невозможно стартовать CHPP авторизацию.'
    },
    invalid: {
      credentials: 'Неверные параметры доступа.',
      token: 'Токен аутентификации не верен.',
      request: 'Неверный запрос.'
    },
    limit: {
      filters: 'Вы достигли лимита на количество фильтров, которые можно создать.'
    },
    missing: {
      token: 'Токен аутентификации отсутствует.'
    },
    conflict: {
      username: 'Имя пользователя уже занято.',
      username_password: 'Имя пользователя и/или e-mail адрес уже заняты.'
    }
  }
}
